import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
// import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';

export default function SignIn() {
    const [openError, setOpenError] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "email": data.get("email"),
            "password": data.get("password")
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.amoris.redes.unb.br/sessions", requestOptions)
            .then(async response => {
                let data = await response.json()
                if ("token" in data) {
                    sessionStorage.setItem('token', JSON.stringify(data))
                    sessionStorage.setItem('session',0)
                    navigate("/Dashboard/Map")
                }
                else {
                    setOpenError(true)
                }
            })
            .catch(error => console.log('error', error))
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Dashboard Botão de Pânico
                </Typography>
                <br></br>
                {openError === true &&
                    <Alert severity="error">O email e senha informados não corresponde, por favor verifique e tente novamente</Alert>
                }
                {/* {openError
                    ? <Alert severity="error">O email e senha informados não corresponde, por favor verifique e tente novamente</Alert>
                    : <Alert severity="error">Correto</Alert>
                } */}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Entrar
                    </Button>
                    {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
                </Box>
            </Box>
        </Container>
    );
}