import React, { useRef, useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Header from "./Header";
import './App.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import moment from 'moment';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const defaultCenter = [-15.76378, -47.86933];
const defaultZoom = 16;

function Mapa() {
    const mapRef = useRef();
    const [position, setPosition] = useState([]);
    const [events, setEvents] = useState([]);
    const [datetime, setDatetime] = useState([]);

    const onClose = async (id) => {
        const tokenString = sessionStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
    
        const requestOptions = {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            status: 0,
          }),
        };
        const data = await fetch(
          "https://api.amoris.redes.unb.br/sos",
          requestOptions
        );

      };

    const defineSSE = () => {
        // Events to render
        const sse = new EventSource('https://api.amoris.redes.unb.br/events', { withCredentials: false, 'Access-Control-Allow-Origin': 'https://api.amoris.redes.unb.br/events' });

        sse.addEventListener('message', (e) => {
            console.log(e)
        });

        sse.onerror = (e) => {
            console.error(e);
            sse.close();
        }

        sse.onmessage = (e) => {
            const eData = e.data
            if (eData.includes('SOS')) {
                setEvents([eData, ...events]);
            }
        };
    }

    useEffect(() => {
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        const token = userToken?.token
        

        const sse = new EventSource('https://api.amoris.redes.unb.br/events', { withCredentials: false, 'Access-Control-Allow-Origin': 'https://api.amoris.redes.unb.br/events' });

        sse.addEventListener('message', (e) => {
            console.log("evento" ,e)
            setDatetime(e)
        });

        sse.onerror = (e) => {
            console.error(e);
            sse.close();
        }
        sse.onopen = (e) => {

            console.log("conexao aberta", e)
        }
        sse.onmessage = (e) => {
            const eData = e.data
            console.log("mensagem recebida", e)
            if (eData.includes('SOS')) {
                setEvents([eData, ...events]);
            }
        };

        // List SOS
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const fetchData = async () => {
            const data = await fetch("https://api.amoris.redes.unb.br/sos", requestOptions)
            const json = await data.json()
            const sos = []
            for (const prop in json) {
                if(json[prop].status === true) {
                    const prop_data = json[prop]
                    json[prop].google_maps = "https://maps.google.com/?q=" + parseFloat(prop_data.user_location.split(" ")[0]) + ',' + parseFloat(prop_data.user_location.split(" ")[1])
                    sos.push(json[prop])
                }
           
            }

            return sos
        }

        fetchData().then((result) => setPosition((result))
            // here you can fill the usersList
        );

        // .catch(error => console.log('error', error));

        return () => {
            console.log("Closing SSE Conection...")
            sse.close()
        };

    }, [events]);
    

    return (
        
        <div>
            <Header /> <br/>
            <section>
            data hora
        </section> <br/>
            <MapContainer ref={mapRef} center={defaultCenter} zoom={defaultZoom}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors" />
                {position.filter((item) => !!item.user_location.split(" ")[1]).filter((item) => !!item.user_location.split(" ")[0]).map((item, i) => (
                    <Marker key={i} position={[parseFloat(item.user_location.split(" ")[0]).toFixed(4), parseFloat(item.user_location.split(" ")[1]).toFixed(4)]}>
                        <Popup>
                            Encerrar chamado: <Button variant="text" size="small">{item.id}</Button> <br></br>
                            Data: {moment(item.updatedAt).format("DD/MM/YYYY hh:mm A")} <br></br>
                            Nome: {item.name} <br></br>
                            Contato: {item.whatsapp} <br></br>
                            Coordenadas: {parseFloat(item.user_location.split(" ")[0]).toFixed(4)}, {parseFloat(item.user_location.split(" ")[1]).toFixed(4)} <br></br>
                            Localização: <a href={item.google_maps} target="_blank" rel="noreferrer">Open Google Maps</a>
                             
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
}

export default Mapa;
