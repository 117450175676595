import React from 'react';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import SingIn from './Login';
import Map from './Map';
import Tabela from './Tabela';
import Form from './Form';
import useToken from './useToken';

const Private = ({Component}) => {
    const { token, setToken } = useToken();

    return token ? <Component /> :  <Navigate to="/" />

}


function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<SingIn />} />
                <Route path="/Dashboard/Map" element={<Private Component={Map} />} />
                <Route path="/Dashboard/Tabela" element={<Private Component={Tabela} />} />
                <Route path="/Dashboard/Form" element={<Private Component={Form} />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;