import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import moment from 'moment';

import "./App.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        Chamado <b>{selectedValue}</b> encerrado com sucesso!
      </DialogTitle>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function Tabela() {
  const [position, setPosition] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: 'updatedAt',
      sort: 'desc',
    },
  ]);

  const renderButton = (params) => {
    if(params.row.status !== 'Encerrado'){
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ marginLeft: 16 }}
          onClick={() => {
            OnClick(params.id)
        }}
      
        >
          Encerrar
        </Button>
      </strong>
    
    )} else {
      return (
<strong>
        <Button

          variant="contained"
          color="primary"
          size="medium"
          style={{ marginLeft: 16 }}
         disabled
      
        >
          Encerrado
        </Button>
      </strong>

      )

    };
  };

  const columns = [
    { field: "id", flex: 1, minWidth: 90,
    renderHeader: () => (
      <h3>
        {' ID'}
      </h3>
    ), },
    { field: "name", flex: 1, minWidth: 150,
    renderHeader: () => (
      <h3>
        {' Nome'}
      </h3>
    ), },
    { field: "whatsapp", flex: 1, minWidth: 150,
    renderHeader: () => (
      <h3>
        {' Contato'}
      </h3>
    ), },
    { field: "status", flex: 1, minWidth: 150,
    renderHeader: () => (
      <h3>
        {'Status'}
      </h3>
    ), },
    { field: "updatedAt", flex: 1, minWidth: 150,
    valueFormatter: params => 
      moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    renderHeader: () => (
      <h3>
        {'Data Atualização'}
      </h3>
    ), },
    {
      field: "google_maps",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <a href={params.value}>Google Maps</a>,
      renderHeader: () => (
        <h3>
          {' Localização '}
        </h3>
      ),
    },
    {
      field: "",
      flex: 1,
      minWidth: 150,
      renderCell: renderButton,
      renderHeader: () => (
        <h3>
          {' Atualizar '}
        </h3>
      ),
    },
  ];

  const OnClick = async (id) => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        status: 0,
      }),
    };
    const data = await fetch(
      "https://api.amoris.redes.unb.br/sos",
      requestOptions
    );

    setSelectedValue(id);
    handleClickOpen();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    function getToken() {
      const tokenString = sessionStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      return userToken?.token;
    }

    function handleMessage(message) {
      // setEvents([message, ...events]);
      if (message.includes("SOS")) {
        setEvents([message, ...events]);
      }
    }

    const token = getToken();

    // Events to render
    const sse = new EventSource("https://api.amoris.redes.unb.br/events", {
      withCredentials: false,
      "Access-Control-Allow-Origin": "https://api.amoris.redes.unb.br/events",
    });

    sse.addEventListener("message", (e) => {
      console.log(e);
    });

    sse.onerror = (e) => {
      console.error(e);
      sse.close();
    };

    sse.onmessage = (e) => {
      handleMessage(e.data);
    };

    // List SOS
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const fetchData = async () => {
      const data = await fetch(
        "https://api.amoris.redes.unb.br/sos",
        requestOptions
      );
      const json = await data.json();
      for (const prop in json) {
        const prop_data = json[prop];
        json[prop].google_maps =
          "https://maps.google.com/?q=" +
          parseFloat(prop_data.user_location.split(" ")[0]) +
          "," +
          parseFloat(prop_data.user_location.split(" ")[1]);
        json[prop].status = prop_data.status === true ? "Ativo" : "Encerrado";
        
      }

      return json;
    };

    fetchData().then(
      (result) => setPosition(result)
      // here you can fill the usersList
    );
    // .catch(error => console.log('error', error));

    return () => {
      console.log("Closing SSE Conection...");
      sse.close();
    };
  }, [events]);

  return (
    <div>
      <Header />
      <div style={{ marginTop: "5%" }}>
        <div style={{ height: "80%", width: "100%" }}>
          <DataGrid
            sortModel={sortModel}
            onSortModelChange={(model)=> setSortModel(model)}
            rows={position}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 30, 100]}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </div>
    </div>
  );
}

export default Tabela;
