import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  useScrollTrigger,
  makeStyles
} from "@material-ui/core";
import TabContext from '@mui/lab/TabContext';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { suppressDeprecationWarnings } from "moment";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    marginLeft: "auto"
  },
  tab: {
    textTransform: "none",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "1rem"
  },
  buttonLogout: {
    marginRight: "auto"
  }
}));

const Header = () => {
  const classes = useStyles();

  const [value, setValue] = useState('1');
  const [userProfile, setUserProfile] = useState('')

  useEffect(() => {
    setUserProfile(JSON.parse(sessionStorage.getItem("token")).user?.profile)
    const session = sessionStorage.getItem("session")
    if(session < 1){
        sessionStorage.setItem("session", 1)
        document.location.reload(true)

    }
  }, [value]);

  const handleChange = (e) => {
   
    setValue(e);
   
  };

  function ElevationScroll(props) {
    
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0
    });

    return React.cloneElement(children, {
      elevation: trigger ? 5 : 0
    });
  }

  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
  };

  const Sair = () => {
    sessionStorage.clear("token")
    sessionStorage.clear("session")
    document.location.reload(true)
  };

  return (
    <ElevationScroll>
      <AppBar position="fixed" style={{ background: '#2E3B55' }}>
        <Toolbar>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                className={classes.tabContainer}
                value={value}
                onChange={(e) => {handleChange(e.target.value) }}
                indicatorColor="transparent"
              >
                <Tab className={classes.tab} component={Link} to="/Dashboard/Map" label="Mapa" value="" />
                <Tab
                  className={classes.tab}
                  component={Link}
                  to="/Dashboard/Tabela"
                  label="Tabela"
                  value="2"
                />
                <Tab
                  className={userProfile === 'ADMIN' ? classes.tab: 'd-none'}
                  component={Link}
                  to="/Dashboard/Form"
                  label="Cadastro"
                  value="3"
                />
              </Tabs>
            </Box>
          </TabContext>
          <Button variant="contained" onClick={Sair}>Sair</Button>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

export default Header;
