import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Button, Stack } from '@mui/material';
import PropTypes from "prop-types";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Header from "./Header";
 

const RegisterForm = () => {
    const [name, setName] = useState('')
    const [profile, setProfile] = useState('')
    const [number, setNumber] = useState('')
    const [matricula, setMatricula] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [data, setData] = useState('')
    const [password, setPassword] = useState('')
    const [shrink1, setShrink1] = useState(true)
    const [selectedValue, setSelectedValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [userProfile, setUserProfile] = useState('')
    const navigate = useNavigate();
    
    useEffect(() => {
        setUserProfile(JSON.parse(sessionStorage.getItem("token")).user?.profile)
        
      }, []);

      if(userProfile !== 'ADMIN'){
        navigate("/Dashboard/Map");
    }

    function getToken() {
        const tokenString = sessionStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        return userToken?.token;
      }

      function SimpleDialog(props) {
        const { onClose, selectedValue, open } = props;
      
        const handleClose = () => {
          onClose(selectedValue);
        };
      
        return (
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
              Mensagem:  
              <b>{selectedValue}</b>
            </DialogTitle>
          </Dialog>
        );
      }

      SimpleDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        selectedValue: PropTypes.string.isRequired,
      };
    
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = getToken();
      
        try {
            const response = await fetch('https://api.amoris.redes.unb.br/users', {
                method: "POST",
                headers: {
              "Content-Type": "application/json"
                },
            body:
                JSON.stringify({
                  profile: profile,
                  name: name,
                  email: email, 
                  password: password,
                  whatsapp: number,
                  address: address,
                  matricula_unb: matricula,
                  risk_group: "",
                  link_unb: " ",
                  user_location: "0",
                  type: " ",
                  birth_date: data
                })
            }
                );
            if(response.status === 200) {
                setSelectedValue("Usuário criado com sucesso!");
                handleClickOpen();
                Reset()
            }
          } catch (err) {
            if(!err.reponse ){
                setSelectedValue(' Serviço de criação de usuário indisponível.');
                handleClickOpen();
            } else if (err.reponse.status === 400) {
                setSelectedValue(" Tipo de dado incorreto. Contate o suporte.");
                handleClickOpen();
            } else if (err.response.status === 401){
                setSelectedValue(" Dados inválidos. Verifique os dados inseridos!");
                handleClickOpen();
            } else {
                setSelectedValue(" Não foi possível criar usuário.");
                handleClickOpen();
            }
         
        }
      
    }

    const handleMatricula = (event) => {
        event.preventDefault()
        const regex = /^[0-9\b]+$/
        if(regex.test(event.target.value) || event.target.value === '') {
            setMatricula(event.target.value)
        }   
    }

    const handleNumero = (event) => {
        event.preventDefault()
        const regex = /^[0-9\-\(\)]+$/
        if(regex.test(event.target.value) || event.target.value === '') {
            setNumber(event.target.value)
        }
    }

    const handleProfile = (event) => {
        event.preventDefault()
        setProfile(event.target.value);
        
      }

    const handleDate = (event) => {
        event.preventDefault()
        var data = event.target.value
        setDateOfBirth(data)
        var ano = data.substring(0,4)

        var mes = data.substring(5,7)

        var dia = data.substring(8,10)
        
        var dataNascimento = dia + '/' + mes + '/' + ano
        
        setData(dataNascimento)
    }

    const Reset = () => {
        setNumber('')
        setMatricula('')
        setEmail('')
        setName('')
        setAddress('')
        setPassword('')
        setDateOfBirth('')
        setProfile('')
    }
    
 
    return (
        <>
         <Header />
            <h2>Cadastro de usuário</h2>
            <form onSubmit={handleSubmit} >
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Nome"
                        onChange={e => setName(e.target.value)}
                        value={name}
                        fullWidth
                        required
                        sx={{mb: 4}}
                    />
            <FormControl sx={{ minWidth: 300}}>
            <InputLabel id="demo-simple-select-autowidth-label">Perfil</InputLabel>    
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={profile}
                    onChange={e => {handleProfile(e)} }
                    label="Perfil"
                    defaultValue=''
                    >
                    
                    <MenuItem value='Admin'>Administrador</MenuItem>
                    <MenuItem value='PROFISSIONAL'>Profissional</MenuItem>
                    <MenuItem value='PACIENTE'>Paciente</MenuItem>
                    <MenuItem value='VIGILANTE'>Vigilante</MenuItem>
                    <MenuItem value='VOLUNTARIO'>Voluntário</MenuItem>
                </Select>
            </FormControl>
            </Stack>
                    <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Telefone"
                        onChange={e => handleNumero(e)}
                        value={number}
                        inputProps={{ 
                            inputMode: 'numeric', 
                        }}  
                        fullWidth
                        required
                    />
                
                <TextField
                    type="email"
                    variant='outlined'
                    color='primary'
                    label="E-mail"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    fullWidth
                    required
          
                />
                </Stack>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                <TextField
                    type="password"
                    variant='outlined'
                    color='primary'
                    label="Senha"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    required
                    fullWidth
              
                />
                
                <TextField
                    type="date"
                    variant='outlined'
                    color='primary'
                    label="Data de Nascimento"
                    onChange={e => handleDate(e)}
                    value={dateOfBirth}
                    fullWidth
                    required
                    onSelect={() => setShrink1(true)}
                    InputLabelProps={{ shrink: shrink1 }}
                    sx={{mb: 4}}
                />
                </Stack>
                <Stack spacing={2} direction="row">
                <TextField
                    type="text"
                    variant='outlined'
                    color='primary'
                    label="Endereço"
                    onChange={e => setAddress(e.target.value)}
                    value={address}
                    fullWidth
                    required
                    sx={{mb: 4}}
                />

                <TextField
                    type="text"
                    variant='outlined'
                    color='primary'
                    label="Matricula"
                    onChange={e => handleMatricula(e)}
                    value={matricula}
                    inputProps={{ 
                        inputMode: 'numeric', 
                 }}  
                    fullWidth
                    required
                    sx={{mb: 4}}
                />
                </Stack>
                
                <Stack spacing={2} direction="row" >
                    <Button variant="contained" color="primary" type="submit">Enviar</Button>
                    <Button variant="outlined" color="primary" onClick={Reset}>Limpar</Button>
                </Stack>
            </form>
            <SimpleDialog
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
        />
        </>
    )
}
 
export default RegisterForm;